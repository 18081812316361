import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import SitesList from '@/components/SitesList';

export function SitesIndex({
  location,
  organizationId,
}: {
  location: any;
  organizationId: string;
}) {
  return (
    <AppFrame organizationId={organizationId}>
      <SitesList
        newSiteId={location?.state?.newSiteId}
        organizationId={organizationId}
      />
    </AppFrame>
  );
}

export default withAuthenticationRequired(SitesIndex);

import { useState } from 'react';
import { useApi } from '@/hooks/use-api';
import api from '@/utilities/api';

import SitesListView from '@/components/SitesList/SitesListView';

const defaultProps = {};

type SitesListContainerProps = {
  newSiteId?: string | undefined;
  organizationId: string | number;
} & typeof defaultProps;

export function SitesListContainer({
  newSiteId,
  organizationId,
}: SitesListContainerProps) {
  const options = {};
  const params = [organizationId];
  const { data, error, loading } = useApi(
    api.sites.list.bind(api.sites),
    params,
    options,
  );
  const [listState, setListState] = useState<any>({});
  return (
    <SitesListView
      error={error}
      listState={listState}
      loading={loading}
      newSiteId={newSiteId}
      organizationId={organizationId}
      sites={data?.sites}
      setListState={setListState}
    />
  );
}

SitesListContainer.defaultProps = defaultProps;

export default SitesListContainer;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import EastIcon from '@mui/icons-material/East';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import IconDropdownMenu from '@/components/core/IconDropdownMenu';
import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';
import SitesDetailActions from '@/components/SitesDetailActions';
import UndrawLandingPage from '@/assets/svgs/undraw-landing-page.svg';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LandingSite20211012Site } from '@clients/landing-site';

const defaultProps = {};

type ListStateProps = {
  isMenuOpen: boolean;
};

type SitesListViewProps = {
  error: any;
  listState: Record<string, ListStateProps>;
  loading: boolean;
  newSiteId: string;
  organizationId: string | number;
  sites: Array<LandingSite20211012Site>;
  setListState: Function;
} & typeof defaultProps;

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export function SitesListView({
  error,
  listState,
  loading,
  newSiteId,
  organizationId,
  sites,
  setListState,
}: SitesListViewProps) {
  const { t } = useTranslation();
  const setMenuStateById = (id: string, isOpen: boolean) => {
    setListState({
      ...listState,
      [id]: {
        ...(listState[id] ? listState[id] : {}),
        isMenuOpen: isOpen,
      },
    });
  };
  const [newSiteSnackbar, setNewSiteSnackbar] = useState({
    open: !!newSiteId,
    vertical: `bottom`,
    horizontal: `left`,
  });

  const handleNewSiteSnackbarClose = () => {
    setNewSiteSnackbar({ ...newSiteSnackbar, open: false });
  };

  return (
    <Box>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Typography color="text.primary">{t(`sites.sites`)}</Typography>
        </Breadcrumbs>
        <Stack
          direction={{ xs: `row`, sm: `row` }}
          justifyContent="space-between"
        >
          <Typography
            variant="h1"
            color="inherit"
            sx={{ mb: { xs: 1, sm: 0 } }}
          >
            {t(`sites.sites`)}
          </Typography>
          <Box>
            {!error && (
              <Button
                component={GatsbyLink}
                endIcon={<EastIcon />}
                to={`/organizations/${organizationId}/blueprints`}
                variant="contained"
              >
                {t(`sites.create.blueprint-zero-state.select-a-blueprint`)}
              </Button>
            )}
          </Box>
        </Stack>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label={t(`sites.sites`)}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t(`sites.table-header.name`)}</TableCell>
                    <TableCell>{t(`sites.table-header.status`)}</TableCell>
                    <TableCell>{t(`sites.table-header.domain-name`)}</TableCell>
                    <TableCell>{t(`sites.table-header.created-at`)}</TableCell>
                    <TableCell align="right">
                      {t(`sites.table-header.actions`)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ borderBottom: `unset` }}>
                        <PageLoading>{t(`sites.loading`)}</PageLoading>
                      </TableCell>
                    </TableRow>
                  ) : error ? (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ borderBottom: `unset` }}>
                        <PageError>
                          {error.message || t(`sites.error`)}
                        </PageError>
                      </TableCell>
                    </TableRow>
                  ) : sites && sites.length ? (
                    sites.map((site) => (
                      <TableRow
                        key={site.id}
                        sx={{
                          backgroundColor:
                            newSiteId === site.id ? `yellow.50` : `inherit`,
                        }}
                      >
                        <TableCell>
                          <Stack
                            direction={{ xs: `row`, sm: `row` }}
                            spacing={1}
                          >
                            <Link
                              to={`/organizations/${organizationId}/sites/${site.id}`}
                              component={GatsbyLink}
                            >
                              {site?.name}
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell style={{ width: 150 }}>
                          <Chip
                            color={
                              site?.state === `ONLINE` ? `success` : `error`
                            }
                            label={site.state}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{site?.domain?.name}</TableCell>
                        <TableCell style={{ width: 250 }}>
                          {new Intl.DateTimeFormat(`en-US`, {
                            dateStyle: `medium`,
                            timeStyle: `short`,
                          }).format(site?.createdAt)}
                        </TableCell>
                        <TableCell align="right" style={{ width: 100 }}>
                          <IconDropdownMenu
                            aria-label={t(`common.menu.more-actions`)}
                            disabled={false}
                            onClick={() => setMenuStateById(site.id, true)}
                            onClose={() => setMenuStateById(site.id, false)}
                            icon={<MoreVertIcon />}
                            isOpen={listState[site.id]?.isMenuOpen}
                          >
                            <SitesDetailActions
                              isOpen={listState[site.id]?.isMenuOpen}
                              siteId={site?.id}
                              onClose={() => setMenuStateById(site.id, false)}
                              organizationId={organizationId}
                            />
                          </IconDropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ borderBottom: `unset` }}>
                        <Box
                          sx={{
                            maxWidth: 640,
                            my: 7,
                            mx: `auto`,
                            textAlign: `center`,
                          }}
                        >
                          <UndrawLandingPage height="200" width="200" />
                          <Typography variant="h5">
                            {t(`sites.zero-state.no-sites`)}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: `text.secondary`, mb: 2 }}
                          >
                            {t(`sites.zero-state.add-one-now`)}
                          </Typography>
                          <Box>
                            <Button
                              color="secondary"
                              component={GatsbyLink}
                              endIcon={<EastIcon />}
                              to={`/organizations/${organizationId}/blueprints`}
                              variant="text"
                            >
                              {t(
                                `sites.create.blueprint-zero-state.select-a-blueprint`,
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: newSiteSnackbar.vertical,
          horizontal: newSiteSnackbar.horizontal,
        }}
        autoHideDuration={6000}
        key={newSiteSnackbar.vertical + newSiteSnackbar.horizontal}
        onClose={handleNewSiteSnackbarClose}
        open={newSiteSnackbar.open}
        TransitionComponent={TransitionUp}
      >
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          onClose={handleNewSiteSnackbarClose}
          severity="success"
          sx={{ width: `100%` }}
        >
          <AlertTitle>{t(`sites.create.success.created-your-site`)}</AlertTitle>
          {t(`sites.create.success.online-soon`)}
        </Alert>
      </Snackbar>
    </Box>
  );
}

SitesListView.defaultProps = defaultProps;

export default SitesListView;
